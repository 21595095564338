<template>
  <div class="form-control">
    <p class="form-control__placeholder">
      {{ placeholder }} <span class="form-control__placeholder_required" v-if="required">*</span>
    </p>
    <div class="collect-editor" :class="{ 'collect-editor--invalid': validateError }">
      <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
      <p class="form-control__error">{{ validateError }}</p>
    </div>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@/plugins/ckeditor/ckeditor.js";
import UploadAdapter from "@/plugins/ckeditor/upload-adapter";
export default {
  props: {
    value: { type: String },
    required: { type: Boolean, default: false },
    validateError: { type: String },
    placeholder: { type: String, required: false },
    toolbar: {
      type: Array,
      default() {
        return [
          "heading",
          "bold",
          "italic",
          "underline",
          "link",
          "blockQuote",
          "|",
          "outdent",
          "indent",
          "alignment",
          "numberedList",
          "bulletedList",
          "|",
          "fontfamily",
          "fontsize",
          "fontColor",
          "-",
          "mediaEmbed",
          "uploadImage", // uploadImage / imageInsert
          "sourceEditing",
          "removeFormat",
          "|",
          "undo",
          "redo",
        ];
      },
    },
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        extraPlugins: [
          function (editor) {
            editor.plugins.get("FileRepository").createUploadAdapter = loader => {
              return new UploadAdapter(loader, "/api/admin/file-uploader");
            };
          },
        ],
        mediaEmbed: {
          previewsInData: true,
        },
        fontSize: {
          options: [10, 12, 14, 16, 18, "default", 22, 24],
        },
        toolbar: {
          items: [],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: [
            "imageStyle:block",
            "imageStyle:inline",
            "imageStyle:alignLeft",
            "imageStyle:alignRight",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "linkImage",
          ],
        },
      },
    };
  },
  created() {
    this.editorConfig.toolbar.items = this.toolbar;
    this.editorData = this.value;
  },
  watch: {
    editorData(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    // async uploader(editor) {
    //   const router = "/api/admin/file-uploader";
    //   editor.plugins.get("FileRepository").createUploadAdapter = function(loader) {
    //   };
    // },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  margin-top: 10px;
  &__placeholder {
    position: absolute;
    left: 2.4rem;
    transform: translateY(-50%);
    @include type($fs: 1.85rem, $fw: 500);
    color: $black;
    background: $white;
    padding: 1px 1.1rem;
    z-index: 2;
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      margin-left: 4px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>


import Vue from "vue";
import store from "axios";

export default class UploadAdapter {
    constructor(loader, router = '') {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.router = router;
    }

    // Starts the upload process.
    async upload() {

        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                this._initRequest();
                this._initListeners(resolve, reject, file);
                this._sendRequest(file);
            }));
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    // Initializes the XMLHttpRequest object using the URL passed to the constructor.
    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        const BASE_URL = process.env.VUE_APP_BACKEND_URL;
        const router = this.router;

        xhr.open('POST', `${BASE_URL}${router}`, true);

        let token = Vue.$cookies.get("BGAtoken");
        if (token) this.xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        xhr.responseType = 'json';
    }

    // Initializes XMLHttpRequest listeners.
    _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener('error', () => reject(genericErrorText));
        xhr.addEventListener('abort', () => reject());
        xhr.addEventListener('load', () => {

            const response = xhr.response;

            if (!response || response.error) {
                return reject(response && response.error ? response.error.message : genericErrorText);
            }
            if(response.status === 'error') {
                return reject(response.response.invalid[0]);
            }
            resolve({
                default: response.response.valid[0],
            });
        });

        if (xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if (evt.loaded === evt.total) {

                }
                if (evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    // Prepares the data and sends the request.
    async _sendRequest(file) {
        const fd = new FormData();
        fd.append('files[]', file)
        this.xhr.send(fd);
    }


}
